import React from 'react';
import styled from 'styled-components';
import { Box, Container, DetailsLink, Grid, Image, Jobs, Layout, LinedHeader, Notice, Section, Typography } from '../components';
import { emailAddress } from '../config';
import { careerFeatures, perks } from '../config/data';
import { RightArrowIcon } from '../icons';

const NoticeLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

const CareersPage = () => (
  <Layout title="Careers">
    <Container mt={{ xs: 8, md: 12 }}>
      <Section>
        <Grid gridTemplateColumns={{ _: '1fr', sm: '1fr 2fr' }}>
          <Box>
            <LinedHeader heading="Current Openings" showRightBorder color="#fbb27e">
              <Typography variant="heading5">Work at Spring Rain.</Typography>
              <DetailsLink to="#jobs" variant="arrow" mt={3}>
                Search Jobs
              </DetailsLink>
            </LinedHeader>
          </Box>
          {/* <Box>
            <LinedHeader
              heading="Jump start your career!"
              showRightBorder={false}
              color="#188DCD"
            >
              <Grid
                alignItems="center"
                gridTemplateColumns={{ _: '1fr', sm: '1fr 1fr 1fr' }}
              >
                <Box>
                  <Typography variant="heading5">
                    Opportunity for fresh CSE or CS graduates.
                  </Typography>
                  <Typography variant="paragraph2" mt={3}>
                    Full Stack Engr. (Node/React)
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="heading5">
                    Only learn No production or real work
                  </Typography>
                  <Typography variant="paragraph2" mt={3}>
                    Learn from home
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="heading5">
                    Only 10 Seats Available!
                  </Typography>
                  <a href={`mailto:${emailAddress}`}>
                    <Button variant="contained" color="primary" mt={3}>
                      Send your CV
                    </Button>
                  </a>
                </Box>
              </Grid>
            </LinedHeader>
          </Box> */}
        </Grid>
      </Section>

      <Section id="jobs">
        <Jobs />
      </Section>

      <Section>
        <Typography variant="heading1">Life at Spring Rain</Typography>
        <Typography variant="heading5" mt={2} mb={6}>
          Equality, Opportunity, and Growth. For Everyone.
        </Typography>

        <Grid gridTemplateColumns={{ _: '1fr', sm: '1fr 1fr 1fr' }}>
          {careerFeatures.map(feature => (
            <Box key={feature.title}>
              <Image mb={4} src={feature.image} alt={feature.title} />
              <Typography variant="heading5" mb={1}>
                {feature.title}
              </Typography>
              <Typography variant="paragraph2">{feature.content}</Typography>
            </Box>
          ))}
        </Grid>
      </Section>

      <Section>
        <Typography variant="heading3" mb={1}>
          Perks of working with us
        </Typography>
        <Typography variant="paragraph2" mb={8}>
          In-house breakfast, lunch, and bottomless snacks; 2 holidays each weekend comprehensive medical insurance overtime payments performance bonus guaranteed yearly increments townhall meetings office tours, and much more.
        </Typography>

        <Grid gridTemplateColumns={{ _: '1fr', sm: '1fr 1fr 1fr' }}>
          {perks.map(perk => (
            <Box borderLeft="1px dashed #142944" pl={3} key={perk.title}>
              <Image mb={2} src={perk.image} alt={perk.title} />
              <Typography variant="paragraph1" mb={1}>
                {perk.title}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Section>

      <Section>
        <NoticeLink href={`mailto:${emailAddress}`}>
          <Notice img="career/notice-image-02.png">
            <Typography variant="paragraph2" mb={2}>
              Don't worry if you can't find your desired position; let us know your expertise, and we'll keep you in mind for upcoming openings.
            </Typography>

            <Box display="flex" alignItems="center">
              <Typography variant="paragraph2" mr={1}>
                Send your CV
              </Typography>
              <RightArrowIcon fill="#188DCD" />
            </Box>
          </Notice>
        </NoticeLink>

        <Typography variant="subtitle1" mt={3} color="light">
          <em>Disclaimer:</em> The HR team will conduct all communications through Spring Rain's email address.
        </Typography>
      </Section>
    </Container>
  </Layout>
);

export default CareersPage;
